import demobuilderlogobig from "../images/demo-builder-logo-big.png";
import demobuilderlogobigwhite from "../images/demo-builder-logo-big-white.png";
import sugarlabslogo from "../images/sugar-labs-logo.png";
import demobuilderlogo from "../images/demo-builder-logo.png";
import demobuilderlogomini from "../images/demo-builder-logo-mini.png";
import demobuilderlogoico from "../images/demo-builder-logo-ico.png";
import sugarlabslogogray from "../images/sugar-labs-logo-gray.png";
import outfittersdefault from "../images/defaults/outfitters_default.png";

import newsdefault from "../images/defaults/news_default.png";

import defaultimagesl01 from "../images/defaults/news/sugarlabs/default-image-sl-01.jpg"
import defaultimagesl02 from "../images/defaults/news/sugarlabs/default-image-sl-02.jpg"
import defaultimagesl03 from "../images/defaults/news/sugarlabs/default-image-sl-03.jpg"
import defaultimagesl04 from "../images/defaults/news/sugarlabs/default-image-sl-04.jpg"
import defaultimagesl05 from "../images/defaults/news/sugarlabs/default-image-sl-05.jpg"
import defaultimagesl06 from "../images/defaults/news/sugarlabs/default-image-sl-06.jpg"
import defaultimagesl07 from "../images/defaults/news/sugarlabs/default-image-sl-07.jpg"
import defaultimagesl08 from "../images/defaults/news/sugarlabs/default-image-sl-08.jpg"
import defaultimagesl09 from "../images/defaults/news/sugarlabs/default-image-sl-09.jpg"
import defaultimagesl10 from "../images/defaults/news/sugarlabs/default-image-sl-10.jpg"
import defaultimageof01 from "../images/defaults/news/outfitters/default-image-of-01.jpg"
import defaultimageof02 from "../images/defaults/news/outfitters/default-image-of-02.jpg"
import defaultimageof03 from "../images/defaults/news/outfitters/default-image-of-03.jpg"
import defaultimageof04 from "../images/defaults/news/outfitters/default-image-of-04.jpg"
import defaultimageof05 from "../images/defaults/news/outfitters/default-image-of-05.jpg"
import defaultimageof06 from "../images/defaults/news/outfitters/default-image-of-06.jpg"
import defaultimageof07 from "../images/defaults/news/outfitters/default-image-of-07.jpg"
import defaultimageof08 from "../images/defaults/news/outfitters/default-image-of-08.jpg"
import defaultimageof09 from "../images/defaults/news/outfitters/default-image-of-09.jpg"
import defaultimageof10 from "../images/defaults/news/outfitters/default-image-of-10.jpg"

import userdefault from "../images/defaults/user_default.png";
import solutionsdefault from "../images/defaults/solutions_default.png";
import modelight from "../images/mode-01-light.jpg";
import modedark from "../images/mode-02-dark.jpg";
import modeauto from "../images/mode-03-auto.jpg";

import admin from "../images/defaults/persona/admin.jpg";
import charles from "../images/defaults/persona/charles.jpg";
import chris from "../images/defaults/persona/chris.jpg";
import jane from "../images/defaults/persona/jane.jpg";
import jen from "../images/defaults/persona/jen.jpg";
import jim from "../images/defaults/persona/jim.jpg";
import max from "../images/defaults/persona/max.jpg";
import melissa from "../images/defaults/persona/melissa.jpg";
import regina from "../images/defaults/persona/regina.jpg";
import sally from "../images/defaults/persona/sally.jpg";
import sarah from "../images/defaults/persona/sarah.jpg";
import will from "../images/defaults/persona/will.jpg";
import ghost_person from "../images/defaults/persona/ghost_person.png";
import superman from "../images/defaults/persona/superman.jpg";
import wonder_woman from "../images/defaults/persona/wonder_woman.jpg";
