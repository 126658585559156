import {
  onInstanceListFilterChange,
  toggleInstancePreviewModal,
  loadFlashMessage,
  listingEvents,
  loadTooltips,
  changeImage,
  toggleTags,
  toggleEye,
  apiCall
} from './globals.js';

$(function () {
  const slCountdown = $("#sl-countdown");
  if (slCountdown.length) { // Countdown maintenance page
    let targetDate = $("#sl-countdown-deadline").text();
    targetDate = new Date(targetDate);
    let countdownInterval = setInterval(function () {
      let currentDate = new Date();
      let timeDifference = targetDate - currentDate;
      let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      let seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
      let countdownText =
      " in " + days + "d " + hours + "h " + minutes + "m " + seconds + "s";
      slCountdown.text(countdownText);
      if (timeDifference < 0) {
        clearInterval(countdownInterval);
        slCountdown.text(" soon");
      }
    }, 1000);
  }

  // Home Page
  let items = $('.carousel .carousel-item');
  if(items.length > 1) {
    const minPerSlide = 2;

    items.each(function() {
        let el = $(this);
        let next = el.next();
        for (let i = 1; i < minPerSlide; i++) {
            if (!next.length) {
              next = items.first(); // Wrap carousel by using first child
            }
            let cloneChild = next.children().first().clone();
            el.append(cloneChild);
            next = next.next();
        }
    });
  }

  if ($("#profileSettingsForm").length) { // Profile settings page
    'use strict';

    $('.needs-validation').on('submit', function(event) {
      if (!this.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }

      let isValid = true;
      const nameRegex = /^[a-zA-Z0-9\s.-]+$/;
      $('#firstName, #lastName').each((_, input) => {
        const $input = $(input);
        const value = $input.val().trim();

        if (value && !nameRegex.test(value)) {
          event.preventDefault();
          event.stopPropagation();
          loadFlashMessage(`Invalid input in ${$input.attr('placeholder').toLowerCase()}. Please avoid special characters.`, 'error', 'Invalid input detected');
          isValid = false;
          return false;
        }

        const sanitizedValue = value.replace(/[<>"'&]/g, (char) => {
          const escapeChars = { '<': '&lt;', '>': '&gt;', '"': '&quot;', "'": '&#39;', '&': '&amp;' };
          return escapeChars[char] || char;
        });

        $input.val(sanitizedValue);
      });

      $(this).addClass('was-validated');
    });

    changeImage($('#custom-file-2'), $('#selected-avatar'));

    // dark/light theme
    document.querySelectorAll('.mode-option').forEach(option => {
      option.addEventListener('click', () => {
        const mode = option.getAttribute('data-mode');
        console.log('merge');
        apiCall({
            'url': '/profile/updatethememode',
            'method': 'POST',
            'headers': {
            'Content-Type': 'application/x-www-form-urlencoded'
            },
            'data': { 'mode': mode },
          });

        if(mode == 'auto'){

          if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            $('html').attr('data-bs-theme', 'dark');
          }
          else{
            $('html').attr('data-bs-theme', 'light');
          }
        }
        else{
          $('html').attr('data-bs-theme', mode);
        }

        // Optional: Add active class to the selected option
        document.querySelectorAll('.mode-option').forEach(opt => opt.classList.remove('active'));
        option.classList.add('active');
      });
    });
  }

  toggleEye(); // Login page password visibility toggle

  // Instances listing page
  onInstanceListFilterChange();
  toggleTags();

  if($('#instanceModal').length > 0) {
    toggleInstancePreviewModal();
  }

  if($('#flashMessage').length > 0) {
    loadFlashMessage();
  }

  const instancesPage = $('.instances-page');
  if (instancesPage.length > 0) {
    const filter = new URLSearchParams(window.location.search).get('filter');
    listingEvents(`/instances?filter=${filter}`);
  }

  loadTooltips();
});
