// Globals file
import { Tooltip, Modal } from 'bootstrap';


$(function () {
//ALL OF THESE SCRIPTS RUN GLOBALLY ON EVERY PAGE

    //if cookie hasn't been set...
    if ($('[id^="massAnnouncementModal-"]').length > 0) {

        $('[id^="massAnnouncementModal-"]').each(function () {
            var modal = new Modal($(this));
            var modalId = $(this).attr('modal-id-mass-announcement');
            modal.show();
            //Modal has been shown, now set a cookie so it never comes back
            $("#massAnnouncementModalClose-" + modalId).click(function () {
                modal.hide();
                document.cookie = 'massAnnouncementModalCookie-' + modalId + '=true; expires='+(new Date(Date.now()+(86400000*2)).toUTCString())+'; path=/';
            });
        });
    }

    //SERVER TIME
    // Fetch the server time passed from PHP
    let serverTime = $('#time-container').attr('server-time');
    let currentDateTime = new Date(serverTime);

    // Function to format time as a 24-hour format
    function formatTime(date) {
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let day = String(date.getDate()).padStart(2, '0');
        let hours = String(date.getHours()).padStart(2, '0');
        let minutes = String(date.getMinutes()).padStart(2, '0');
        let seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    // Function to update time every second
    function updateTime() {
        currentDateTime.setSeconds(currentDateTime.getSeconds() + 1);
        $('#time-container > #time').html(formatTime(currentDateTime));
    }

    // Initial call and then update every second
    updateTime();
    setInterval(updateTime, 1000);
    
});

const toggleEye = () => {
    const password = $("#password");
    if(password.length > 0) {
        $("#togglePassword").click(function () {
            if (password.attr("type") === "password") {
                password.attr("type", "text");
                $(this).removeClass("isax-eye").addClass("isax-eye-slash");
            } else {
                password.attr("type", "password");
                $(this).removeClass("isax-eye-slash").addClass("isax-eye");
            }
        });
    }
};

const loadTooltips = () => {
    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })
}

function loadFlashMessage ( message = null, type = null, title = null, callback = null) {
    Swal.fire({
        title: title,
        text: message,
        icon: type,
        confirmButtonText: 'Okay',
        confirmButtonColor: "#85c56a",
    }).then((result) => {
        if (result.isConfirmed) {
            if(callback) {
                callback();
            }
        }
    });
}

window.loadFlashMessage = loadFlashMessage;

function loadConfirmationPopup ( message = null, type = null, title = null, confirmButtonColor = "#85c56a", callback = null) {
    Swal.fire({
        title: title,
        html: message,
        icon: type,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Okay',
        confirmButtonColor,
    }).then((result) => {
        if (result.isConfirmed) {
            if(callback) {
                callback();
            }
        }
    });
}

window.loadConfirmationPopup = loadConfirmationPopup;

async function loadPopupform (inputs, title, action, method, callback = null) {
    let htmlInputs = "";
    $.each(inputs, function (index, value) {
        htmlInputs += ` <input id="swal-` + value.name + `"
                            class="swal2-input"
                            name="` + value.name + `"
                            placeholder="` + value.placeholder + `"
                            type="` + value.type + `"
                            value="` + value.value + `"> `;
    });

    const { value: formValues } = await Swal.fire({
        title,
        html: htmlInputs,
        showCancelButton: true,
        confirmButtonColor: "#85c56a",
        preConfirm: () => {
            let completedForm = {};
            $.each(inputs, function (index, val) {
                completedForm[val.name] = $("#swal-"+val.name).val();
            });

            return completedForm;
        }
    });

    if(formValues) {
        try {
            $.ajax({
                url: action,
                type: method,
                data: formValues,
                success: (response) => {
                    var data = JSON.parse(response);
                    loadFlashMessage(data.message, data.type, data.title, callback);
                },
                error: (xhr, status, error) => {
                    loadFlashMessage('The request did not go through, please try again.', 'error', 'Error');
                }
            });
        } catch (error) {
            loadFlashMessage('An unexpected error occurred, please try again.', 'error', 'Error');
        }
    }
    else{
        closeLoader();
    }

}

window.loadPopupform = loadPopupform;

const loadSugarUsersEvents = () => {
    $('.clipboard').each(function() {
        $(this).click((e) => {
            const password = $(e.target).closest('td').prev('td').find('span').text();
            navigator.clipboard.writeText(password)
                .then(function() {
                    const passwordModal = $('#passwordCopiedModal');
                    if(passwordModal.length > 0) {
                        const passwordClipboard = new Modal(passwordModal);
                        passwordClipboard.toggle();

                        setTimeout(function() {
                            passwordClipboard.toggle();
                        }, 1000);
                    }
                })
                .catch(function(err) {
                    console.error('Unable to copy password: ', err);
                });
        });
    })

    $('#showMoreBtn').click(function() {
        const hiddenRows = $('.sl-users-table').find('.d-none');
        if(hiddenRows.length > 0) {
            hiddenRows.removeClass('d-none');
            $(this).text('Show Less');
        } else {
            $('.sl-users-table').find('tr').slice(4).addClass('d-none');
            $(this).text('Show More');
        }
    });
};

const toggleInstancePreviewModal = () => {
    $('.preview-instance').click((e) => {
        const instancePreviewModal = $('#instanceModal');
        const instanceId = $(e.currentTarget).data('instance-id');

        try {
            $.ajax({
                url: `instance/${instanceId}/preview`,
                type: 'GET',
                success: (response) => {
                    instancePreviewModal.html(response);
                    const myModal = new Modal(instancePreviewModal);
                    myModal.show();
                    loadSugarUsersEvents();
                },
                error: (error) => {
                    console.error(error);
                }
            });
        } catch (error) {
            console.error("An unexpected error occurred: ", error);
        }
    });
};

// Function to make AJAX calls to backend
const apiCall = async (options, decode = false) => {
    if (options.method.toUpperCase() === 'POST' && !options.contentType) { // Set default content type if not provided
        options.contentType = 'application/x-www-form-urlencoded';
    }

    return $.ajax(options)
            .then(response => {
                return decode === false ? response : JSON.parse(response); // Assume JSON data for other cases
            })
            .fail(function(xhr, status, error) {
                let errorMessage = 'Unknown error occurred.';
                try {
                    const response = JSON.parse(xhr.responseText);
                    errorMessage = response.error ? response.error : errorMessage;
                } catch (parseError) {
                    console.error(`Status ${status}: `, parseError);
                }
                throw new Error(errorMessage);
            });
};

const toggleTags = () => {
    const toggleLabels = $('label[id^="toggleTags_"]');
    if (toggleLabels.length > 0) {
        toggleLabels.on('click', function() {
            const targetId = $(this).data('target');
            $('#' + targetId).toggle();
            $(this).text(function(_, text) {
                return text === '...' ? 'Hide' : '...';
            });
        });
    }
}

const onInstanceFetchResponse = (data, selectedValue) => {
    const responseData = data;
    $("#container-template").html(responseData);
    window.history.pushState('page', 'title', `/instances?page=1&filter=${selectedValue}`);

    const toggleLabels = $('label[id^="toggleTags_"]');
    if (toggleLabels.length > 0) {
        toggleLabels.on('click', function() {
            const targetId = $(this).data('target');
            $('#' + targetId).toggle();
            $(this).text(function(_, text) {
                return text === '...' ? 'Hide' : '...';
            });
        });
    }
}

const loadFromSearch = async (urlParam, method = "POST") => {
    const isBackofficeForm = $('#searchForm').hasClass('searchField');
    const searchInput = isBackofficeForm ? $('.searchField:focus') : $('#searchForm');
    const inputValue = searchInput.val();
    const hasInput = inputValue.trim() !== '';

    let options = { method: method };

    let isValid = true;
    const datePattern = /^\d{2}-\d{2}-\d{4}$/; // Pattern to match DD-MM-YYYY format
    const allowedKeys = ['created_before', 'expires_after'];
    const keyCount = {
        'created_before': 0,
        'expires_after': 0
    };

    let parts = '';
    if(hasInput) {
        parts = inputValue.split(',').map(part => part.trim());
        parts.forEach(part => {
            const colIndex = part.indexOf(':');

            // Check if there is a colon and it's not the first or last character
            if (colIndex === -1 || colIndex === 0 || colIndex === part.length - 1) {
                loadFlashMessage('Invalid input format. Please use key:value format.', 'warning', `Invalid input for '${part}'`);
                isValid = false;
                return;
            }

            const key = part.substring(0, colIndex).trim();
            const value = part.substring(colIndex + 1).trim();

            // Check length of the value part (excluding the key part)
            if (value instanceof String && value.length <= 3) {
                loadFlashMessage('Each value must be more than 3 characters long.', 'warning', `Invalid input length for '${part}'`);
                isValid = false;
                return;
            }

            // Check if the key is either 'created_before' or 'expires_after' and count occurrences
            if (allowedKeys.includes(key)) {
                keyCount[key]++;

                // Validate date format
                if (!datePattern.test(value)) {
                    loadFlashMessage('Invalid date format. Please use DD-MM-YYYY format.', 'warning', `Invalid date format for '${part}'`);
                    isValid = false;
                    return;
                }
            }
        });
    }

    // Check if there is more than one occurrence of 'created_before' or 'expires_after'
    for (const [key, count] of Object.entries(keyCount)) {
        if (count > 1) {
            loadFlashMessage(`Only one instance of '${key}' is allowed.`, 'warning', `Multiple instances of '${key}' found.`);
            isValid = false;
            break;
        }
    }

    if(isValid) {
        showLoader('.table-responsive');

        if(method === "POST") { // instance listing
            options.url = urlParam;
            options.data = { search: inputValue };
        } else { // Backoffice form
            let urlParam = '';
            if(hasInput) {
                parts.forEach(part => {
                    const [key, value] = part.split(':');
                    const trimmedValue = `${key}=${encodeURIComponent(value.trim())}`;
                    urlParam += urlParam.includes('?') ? `&${trimmedValue}` : `?${trimmedValue}`;
                });
            }

            const baseUrl = isBackofficeForm ? searchInput.data('action') : $('#formBackoffice').attr('action');
            options.url = `${baseUrl}${urlParam}`;
        }

        const response = await apiCall(options);
        if(response.length > 0) {
            const instancesListingCase = $('#filterOptions');
            if(instancesListingCase.length > 0) { // instances listing
                let selectedValue = instancesListingCase.val().toLowerCase();
                urlParam = "/instances?filter=" + selectedValue;
                onInstanceFetchResponse(response, selectedValue);
                toggleInstancePreviewModal();
            }

            if(isBackofficeForm) {
                return JSON.parse(response)['results'];
            }

            listingEvents(urlParam);
        };
    };
}

const closeList = (listClass, inputClass) => {
    $(document).on('click', (event) => {
        const target = $(event.target);
        if (!target.closest(listClass).length && !target.closest(inputClass).length) {
            listClass.removeClass('show');
        }
    });
};

const listingEvents = (urlParam, method = "POST") => {
    const setupEvents = (searchInput, suggestionsList) => {
        searchInput.on('focus', () => { suggestionsList.addClass('show'); });

        suggestionsList.on('click', 'a', function(event) {
            event.preventDefault();
            const dataValue = $(this).attr('data-value');

            const multipleForms = $('#searchForm').hasClass('searchField'); //it's shit but im tired | 12.11.2024: still shit
            const searchInput = multipleForms ? $(event.target).closest('.suggestionsList').siblings('.searchField') : $('#searchForm');
            const currentValue = searchInput.val().trim();
            const parts = currentValue.split(',');
            const hasOpenKey = parts.some(part => {
                const colonIndex = part.indexOf(':');
                return colonIndex !== -1 && colonIndex === part.length - 1;
            });

            if (hasOpenKey) {
                loadFlashMessage('Please complete the key:value pair before adding another value.', 'warning', 'Incomplete key:value pair');
                return;
            }

            const newValue = !multipleForms && currentValue ? `${currentValue}, ${dataValue}` : dataValue;
            searchInput.val(newValue);
            searchInput.focus();
            suggestionsList.removeClass('show');
        });

        searchInput.on('keypress', async function(e) {
            if (e.which === 13) {
                await loadFromSearch(urlParam, method);
            }
        });
    };

    let searchInput = '';
    let suggestionsList = '';
    if($('.searchField').length > 1) {
        $('.searchField').each(function() {
            searchInput = $(this);
            suggestionsList = searchInput.siblings('.suggestionsList');
            setupEvents(searchInput, suggestionsList);
            closeList(suggestionsList, searchInput);
        });
    } else {
        searchInput = $('#searchForm');
        suggestionsList = $('#suggestionsList');
        setupEvents(searchInput, suggestionsList);
        closeList(suggestionsList, searchInput);
        loadTooltips();
    }

};

const onInstanceListFilterChange = () => {
    $(document).on('change', '#filterOptions', function() {
        showLoader('.table-responsive');

        const selectedValue = $(this).val().toLowerCase();
        $.ajax({
            url: `/instances`,
            type: 'GET',
            data: {
                filter: selectedValue
            },
            success: (data) => {
                const filter = $('#filterOptions').val().toLowerCase();
                onInstanceFetchResponse(data, selectedValue);
                listingEvents(`/instances?filter=${filter}`);
                toggleInstancePreviewModal();
                loadTooltips();
            },
            error: (error) => {
                console.error('Error fetching data:', error);
            }
        });
    });
};

async function showLoader(hideEl = "#container-template") {
    const el = $(hideEl);
    $(`
        <div class="lds-roller">
            <div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div>
        </div>
    `).insertBefore(el);

    // el.hide();

    const paginationNav = $('nav[aria-label="Pagination"]');
    paginationNav.remove();

    await new Promise(resolve => setTimeout(resolve, 3000));
}

async function closeLoader(showEl = "#container-template") {
    const spinner = $(".lds-roller");
    if(spinner.length) {
        spinner.remove();
    }

    $(showEl).show();
}

function createTagElement(tagText) {
    return $('<span>', {
        class: 'badge sl-tag mb-1 me-1',
        text: tagText,
        title: 'Click to remove from list',
        click: function() {
            $(this).remove();
        }
    });
};

const addTags = (event, updateOnInsert = false) => {
    const tags = $('#tags');
    const newTags = tags.val().split(',').map(tag => tag.trim().toLowerCase()).filter(tag => tag); // Convert to lowercase and remove empty tags
    const tagContainer = $('#tag-container');
    const addTagIcon = $('#add-tag-icon');

    newTags.forEach(newTag => {
        const existingTag = tagContainer.find('.sl-tag').filter(function() {
                                return $(this).text().trim().toLowerCase() === newTag;
                            });

        if (existingTag.length === 0) {
            const newTagElement = createTagElement(newTag);
            tagContainer.append(newTagElement);
        } else {
            existingTag.remove();
            const newTagElement = createTagElement(newTag);
            tagContainer.append(newTagElement);
        }
    });

    if(updateOnInsert && (event.type === 'click' || event.which === 13)) {
        event.preventDefault();
        const badges = $('.badge').map(function() { return $(this).text(); }).get();
        const form = tags.closest('form');

        apiCall({
                'url': form.attr('action'),
                'method': form.attr('method'),
                'headers': {
                'Content-Type': 'application/x-www-form-urlencoded'
                },
                'data': { 'tags': JSON.stringify(badges) },
            });
    }

    tags.val('');
    addTagIcon.css('opacity', '0');
};

const initializeTagsInput = (updateOnInsert = false) => {
    const tags = $('#tags');
    if(tags.length > 0) {
        const addTagIcon = $('#add-tag-icon');

        tags.on('input keydown', (event) => {
            if (event.type === 'input') {
                addTagIcon.css('opacity', tags.val().trim() ? 1 : 0);
            } else if (event.key === 'Enter') {
                event.preventDefault();
                addTags(event, updateOnInsert);
            }
        });

        addTagIcon.on('click', (event) => { addTags(event, updateOnInsert); });

        $('.badge').each(function() {
            const el = $(this);
            el.on('click', function(e) {
                e.preventDefault();
                el.remove();

                const badges = $('.badge').map(function() { return $(this).text(); }).get();
                const form = tags.closest('form');

                apiCall({
                    'url': form.attr('action'),
                    'method': form.attr('method'),
                    'headers': {
                    'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    'data': { 'tags': JSON.stringify(badges) },
                });
            });
        });

        $('#instance-form').on('submit', function() {
            const tagValues = $('#tag-container').find('.sl-tag').map(function() {
                                    return $(this).text();
                                }).get();

            $('<input>', {
                type: 'hidden',
                name: 'tags',
                value: tagValues.join(',')
            }).appendTo(this);
        });
    };
};

async function loadCkeditor(element, removeButtons) {
    try {
        await import('./instance/ckeditor-loader.js');
        await import('ckeditor4');

        let options = {
            extraPlugins: 'colorbutton,font,uploadimage,image2',
            filebrowserUploadUrl: '/path/to/upload.php',
            filebrowserUploadMethod: 'form',
            versionCheck: false,
            allowedContent: true
        };

        if(removeButtons) {
            options.removeButtons = removeButtons;
        }

        return await CKEDITOR.replace(`${element}`, options);
    } catch (error) {
        console.error('Failed to load CKEditor:', error);
    };
};

const changeImage = (customFile, imageSelector) => {
    customFile.on('change', function(event) {
        const selectedImage = imageSelector[0];
        const fileInput = event.target;
        const file = fileInput.files[0];
        const allowedImageTypes = ['image/jpeg', 'image/png'];
        const maxImageSize = 1024 * 1024; // 1MB
        let isValid = true;

        if (file) {
            const { type, size } = file;

            // Validate file type
            if (!allowedImageTypes.includes(type)) {
                loadFlashMessage('Invalid file type. Only JPEG and PNG are allowed.', 'error', 'Invalid file type');
                isValid = false;
            }

            // Validate file size
            if (size > maxImageSize) {
                loadFlashMessage('File size exceeds 1MB. Please upload a smaller image.', 'error', 'File size limit exceeded');
                isValid = false;
            }

            if (isValid) {
                const reader = new FileReader();
                reader.onload = (e) => { selectedImage.src = e.target.result; };
                reader.readAsDataURL(file);
            }
        }
    });
};

const dynamicComponentUpdate = (parentDivOfComponent, trackedComponent, intervalTime = 60000, disable = false) => {
    if(disable) {
        return;
    }

    const intervalId = setInterval(() => {
        const instanceId = $(`#${trackedComponent}`).data('instance-id');
        apiCall({
            'url': `/instance/${instanceId}/updateComponent/${trackedComponent}`,
            'method': 'GET'
        }).then(response => {
            // Case no response was sent
            if(response.length < 1) {
                disable = true;
                clearInterval(intervalId);
            }

            const responseData = /<[^>]+>/.test(response) ? null : JSON.parse(response);
            // If response is not JSON, it means it is HTML content or if it has 204 (no changes), it means no job finished in the time interval
            if(responseData == null || responseData?.code === 204) {
                // If it has 204, it means no jobs finished in the time interval
                if(responseData?.code === 204) {
                    return;
                }
                $(`#${parentDivOfComponent}`).html(response);
                return;
            // Else, it means it either has no jobs or an error occured
            } else {
                disable = true;
                clearInterval(intervalId);
                return;
            }
        }).catch(error => {
            console.error('Error updating component:', error);
        });
    }, intervalTime);
}

export {
    onInstanceListFilterChange,
    toggleInstancePreviewModal,
    dynamicComponentUpdate,
    loadConfirmationPopup,
    loadSugarUsersEvents,
    initializeTagsInput,
    loadFlashMessage,
    loadFromSearch,
    listingEvents,
    loadPopupform,
    loadTooltips,
    loadCkeditor,
    changeImage,
    closeLoader,
    showLoader,
    toggleTags,
    toggleEye,
    closeList,
    apiCall,
};